import React from 'react'

export const noodling = {
  title: 'noodling',
  desc: () => (<p>Want to see what I have been messing around with lately? Check out this section. <br /> Or for something even fresher, <a href="https://www.instagram.com/supersghp/">try instagram</a> and <a href="https://vimeo.com/sarahghp">vimeo</a>.</p>),
  photos: [
    { img: 'https://sarahghp.com/vids/noodling/noodl06', alt: 'still of circle and eye exploration' },

  ],
  vids: [ 
    { vid: 'https://sarahghp.com/vids/noodling/2022-02-05a-segment-a.mp4', caption: '2022-02-05: original base + fairlight cvi, reordered using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/02-03a-doubled.mp4', caption: '2022-02-03: original base + fairlight cvi, reordered and concatenated using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/2022-01-29a.mp4', caption: '2022-01-29: original base + fairlight cvi' },
    { vid: 'https://sarahghp.com/vids/noodling/rol1-shuffled.mp4', caption: '2022-01-14: original base, reordered using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/01-15a-shuffled.mp4', caption: '2022-01-15: original base + framebuffer, reordered using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/2022-01-08a-shuffled.mp4', caption: '2021-01-08: ooriginal base + framebuffer, reordered using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/01010b3-shuffled.mp4', caption: '2022-01-10: original base + framebuffer, reordered using ffmpeg' },
    { vid: 'https://sarahghp.com/vids/noodling/2021-01-02a.mp4', caption: '2021-01-02: original base + vdmx + framebuffer' },
    { vid: 'https://sarahghp.com/vids/noodling/2020-11-24a.mp4', caption: '2020-11-24: original base + fairlight cvi + framebuffer' },
    { vid: 'https://sarahghp.com/vids/noodling/2020-10-25x.mp4', caption: '2020-10-25: original base + fairlight cvi + uvs + framebuffer' },
  ]
}
